import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import { update_session, delete_session} from '../Utils/Session/Session';
import { EmailValidator, CPValidator} from '../Utils/Inputs/InputValidator';
import api from '../Utils/API';
import AuthService from 'Modules/Utils/AuthService';

import { GoToErrorPage } from '../Utils/V6/NavigateFunction';
import SearchBar from './SearchBar';
import SearchTab from './SearchTab';
import DisconnectionButton from '../Utils/Buttons/DisconnectionButton';
import OverlayLoader from '../Utils/Loader/OverlayLoader';

import avanci_logo from '../../Assets/IMG/Avanci_logo.png';

function SearchPage() {
    
    const navigate = useNavigate();
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    const PAGE_SIZE = 10;
    const title                                             = 'Starkle';
    const [componentLoaded, setComponentLoaded]             = useState(false);
    const [errorHandler, setErrorHandler]                   = useState({
                                                                hasError : false,
                                                                code : ""     
                                                            });
    const [disconnected, setDisconnected]                   = useState(false);
    const [isLoading, setIsLoading]                         = useState(false);
    const [isUpdating, setIsUpdating]                       = useState(false);
    const [cacheRequest, setCacheRequest]                   = useState(false);
    const [contactsRequest, setContactsRequest]             = useState({
                                                                contacts            : [],
                                                                size                : -1,
                                                                requested           : false,
                                                                page                : 0
                                                            });
    const [updatePageNumber, setUpdatePageNumber]           = useState(0);
    const inputs                                            = {
                                                                nom                 : "nomclient",
                                                                nomLabel            : "Nom",
                                                                prenom              : "prenomclient",
                                                                prenomLabel         : "Prenom",
                                                                codePostal          : "codepostalclient",
                                                                codePostalLabel     : "Code postal",
                                                                email               : "emailclient",
                                                                emailLabel          : "Adresse Email",
                                                                carteFid            : "cartefidclient",
                                                                carteFidLabel       : "N° de fidélité",
                                                                id                  : "idclient",
                                                                idLabel             : "Identifiant client"
                                                            };
    const [inputValues, setInputValues]                     = useState(
                                                                {
                                                                    email           : '',
                                                                    nom             : '',
                                                                    prenom          : '',
                                                                    carteFid        : '',
                                                                    codePostal      : '',
                                                                    id              : ''
                                                                }
                                                            );
    const [inputsError, setInputsError]                     = useState({
                                                                email               : false,
                                                                emailMessage        : '',
                                                                nom                 : false,
                                                                nomMessage          : '',
                                                                prenom              : false,
                                                                prenomMessage       : '',
                                                                codePostal          : false,
                                                                codePostalMessage   : '',
                                                                carteFid            : false,
                                                                carteFidMessage     : '',
                                                                id                  : false,
                                                                idMessage           : '',
                                                            });
    
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    // --> component loading
    useEffect(() => {
        try{
            setComponentLoaded(true);
            return () => {
                setComponentLoaded(false);
            }
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000100"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        try{
            if (!update_session()) deconnect();
            else{
                if(sessionStorage.getItem("cached_contact_search_inputs") !== null){
                    setCacheRequest(true);
                    setInputValues(JSON.parse(sessionStorage.getItem("cached_contact_search_inputs")));
                }
            }
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000101"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentLoaded]);

    const deconnect = () => {
        try{
            if(componentLoaded){
                delete_session();
                AuthService.logout();
                setDisconnected(true);
            }
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000102"});
        }
    }

    const searchClient = () => {
        try{
            if(!update_session()) deconnect();
            else getHTMLInputValues();
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000103"});
        }
    }

    // --> Request Search - 1
    const getHTMLInputValues = () => {
        try{ 
            if(componentLoaded){
                // Get values from HTML
                setInputValues({
                    email                   : document.getElementById(inputs.email).value.toString(),
                    nom                     : document.getElementById(inputs.nom).value.toString(),
                    prenom                  : document.getElementById(inputs.prenom).value.toString(),
                    carteFid                : document.getElementById(inputs.carteFid).value.toString(),
                    codePostal              : document.getElementById(inputs.codePostal).value.toString(),
                    id                      : document.getElementById(inputs.id).value.toString()
                });
            }
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000104"});
        }
    };

    useEffect(() => {
        try{
            proceedCheckInputs();
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000105"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValues]);

    
    const validateEmail = (email) => {
        try{
            if(email === '')                    return [false, ""]; 
            else if(!EmailValidator(email))     return [true, "Le format n'est pas celui d'un email"];
            else                                return [false, ""];
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000106"});
        }
    }

    const validateOtherString = (string) =>  [false, ""];

    const validateCP = (string) => {
        try{
            if(string === '')                           return [false, ""];       
            else if(!CPValidator(string))               return [true, "Le format n'est pas celui d'un code postal valide"];
            else                                        return [false, ""];
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000107"});
        }                                 
    }

    const proceedCheckInputs = () => {
        try{
            if(componentLoaded){
                let emailValidation         = validateEmail(inputValues.email);
                let nomValidation           = validateOtherString(inputValues.nom);
                let prenomValidation        = validateOtherString(inputValues.prenom);
                let carteFidValidation      = validateOtherString(inputValues.carteFid);
                let codePostalValidation    = validateCP(inputValues.codePostal);
                let idValidation            = validateOtherString(inputValues.id);

                setInputsError({
                    email               : emailValidation[0],
                    emailMessage        : emailValidation[1],
                    nom                 : nomValidation[0],
                    nomMessage          : nomValidation[1],
                    prenom              : prenomValidation[0],
                    prenomMessage       : prenomValidation[1],
                    codePostal          : codePostalValidation[0],
                    codePostalMessage   : codePostalValidation[1],
                    carteFid            : carteFidValidation[0],
                    carteFidMessage     : carteFidValidation[1],
                    id                  : idValidation[0],
                    idMessage           : idValidation[1],
                });
            }
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000108"});
        }
    }

    useEffect(() => {
        try{
            if (componentLoaded){
                if (!inputsError.password && !inputsError.nom && !inputsError.prenom 
                    && !inputsError.codePostal && !inputsError.carteFid && !inputsError.id) {
                    // vérification si au moins un champs non vide
                    if(inputValues.id === '' && inputValues.email === '' && inputValues.nom === '' && inputValues.prenom === '' 
                        && inputValues.codePostal === '' && inputValues.carteFid === '' && inputValues.id === ''){
                        setInputsError({
                            email               : true,
                            emailMessage        : "",
                            nom                 : true,
                            nomMessage          : "Veuillez spécifier au moins un champs",
                            prenom              : true,
                            prenomMessage       : "",
                            codePostal          : true,
                            codePostalMessage   : "",
                            carteFid            : true,
                            carteFidMessage     : "",
                            id                  : true,
                            idMessage           : "",
                        });
                    }else{
                        // send requests
                        setIsLoading(true);
                    }
                }
            }
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000109"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputsError]);

    useEffect(() => {
        try{
            sendSearchRequest({
                nom         : inputValues.nom, 
                prenom      : inputValues.prenom, 
                code_postal : inputValues.codePostal,
                email       : inputValues.email, 
                carte_fid   : inputValues.carteFid, 
                id          : inputValues.id, 
                page_size   : PAGE_SIZE,
                page        : cacheRequest ? parseInt(sessionStorage.getItem("cached_contact_search_page")) : 0, 
                old_amount  : cacheRequest ? parseInt(sessionStorage.getItem("cached_contact_search_size")) : -1
            }, setIsLoading);
        }
        catch(error){
            setErrorHandler({hasError : true, code : "00010a"});
        }  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    // --> Send request to search for contact
    const sendSearchRequest = (request_fields, f_loads) => {
        try{
            if (componentLoaded){
                if(!update_session()) deconnect();
                else if(isLoading || isUpdating){
                    api.search(request_fields)
                        .then((response) => {
                            try{
                                if(componentLoaded){
                                    // api key authorized
                                    let status = response.request.status;
                                    if(status !== 200) setErrorHandler({hasError : true, code : "000118"});
                                    if(response.data.content === undefined) setErrorHandler({hasError : true, code : "000111"});
                                    if(response.data.content.status === undefined) setErrorHandler({hasError : true, code : "000112"});
                                    if (response.data.content.status !== 1) {
                                        if(response.data.errorMessage === undefined) setErrorHandler({hasError : true, code : "000113"});
                                        setInputsError({
                                            email               : true,
                                            emailMessage        : "",
                                            nom                 : true,
                                            nomMessage          : response.data.errorMessage,
                                            prenom              : true,
                                            prenomMessage       : "",
                                            codePostal          : true,
                                            codePostalMessage   : "",
                                            carteFid            : true,
                                            carteFidMessage     : "",
                                            id                  : true,
                                            idMessage           : "",
                                        });
                                        f_loads(false);
                                    } else {
                                        if(response.data.content.contacts === undefined) setErrorHandler({hasError : true, code : "000116"});
                                        if(response.data.content.amount === undefined) setErrorHandler({hasError : true, code : "000117"});
                                        setContactsRequest({
                                            contacts            : response.data.content.contacts,
                                            size                : response.data.content.amount,
                                            requested           : true,
                                            page                : request_fields.page
                                        });
                                        sessionStorage.setItem("cached_contact_search_inputs", JSON.stringify(inputValues));
                                        sessionStorage.setItem("cached_contact_search_size", JSON.stringify(response.data.content.amount));
                                        sessionStorage.setItem("cached_contact_search_page", JSON.stringify(request_fields.page));
                                        setCacheRequest(false);
                                        f_loads(false);
                                    }
                                }
                            }catch(error){
                                setErrorHandler({hasError : true, code : "000119"});
                            }
                        })
                        .catch((error) => {
                            if (error.response?.status === 401) {
                                return navigate("/");
                            }
                            try{
                                if(componentLoaded){
                                    // api key not authorized
                                    let status = error.response.request.status;
                                    if (status === 401) {
                                        if(error.response.data.errorMessage === undefined) setErrorHandler({hasError : true, code : "000114"});
                                        setErrorHandler({hasError : true, code : "000110"});
                                    }
                                    if (status === 500) {
                                        if(error.response.data.errorMessage === undefined)  setErrorHandler({hasError : true, code : "000115"});
                                        setErrorHandler({hasError : true, code : "00011b"});
                                    } else {
                                        setErrorHandler({hasError : true, code : "00011c"});
                                    }
                                }
                            }catch(error){
                                setErrorHandler({hasError : true, code : "00011a"});
                            }
                        });
                }
            }
        }
        catch(error){
            setErrorHandler({hasError : true, code : "00010b"});
        }  
    }

    // --> Change Page
    const proceedChangePage = (pageNumber) => {
        try{
            if(componentLoaded){
                setUpdatePageNumber(parseInt(pageNumber));
                setIsUpdating(true);
            }
        }
        catch(error){
            setErrorHandler({hasError : true, code : "00010e"});
        }
    }

    useEffect(() => {
        try{
            if(isUpdating) sendSearchRequest({
                nom         : inputValues.nom, 
                prenom      : inputValues.prenom, 
                code_postal : inputValues.codePostal,
                email       : inputValues.email, 
                carte_fid   : inputValues.carteFid, 
                id          : inputValues.id, 
                page        : updatePageNumber,
                page_size   : PAGE_SIZE,
                old_amount  : contactsRequest.size
            }, setIsUpdating)
        }
        catch(error){
            setErrorHandler({hasError : true, code : "00010j"});
        }  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdating]);

    if(!componentLoaded) return(<></>);
    return (
        <Fragment>
            <GoToErrorPage error={errorHandler}></GoToErrorPage>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <OverlayLoader hidden={!(isLoading || isUpdating)} fatherLoaded={componentLoaded} errorCode={"00010i"}></OverlayLoader>

            <div className={"page bg"}>
                
                <header className={"container header"}> 
                    <div className={"row"}> 
                        <div className={"col"}> 
                            <img src="img/logo_client_small.png" alt="" className={"img-fluid"} />
                        </div>
                        <div className={"col alignRight"}> 
                            <nav id={"navTop"}> 
                                <ul>
                                    <li>
                                        <DisconnectionButton deconnect={deconnect} disconnected={disconnected} 
                                            fatherLoaded={componentLoaded} errorCode={"00010h"}></DisconnectionButton>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </header>
                <SearchBar searchClient={searchClient} inputs={inputs} inputsError={inputsError} 
                    cache={
                        cacheRequest ? inputValues : {
                            email           : '',
                            nom             : '',
                            prenom          : '',
                            carteFid        : '',
                            codePostal      : '',
                            id              : ''
                        }
                    } fatherLoaded={componentLoaded}></SearchBar>
                {
                    contactsRequest.requested ?
                        <SearchTab 
                            contacts={contactsRequest.contacts}
                            currentAmount={contactsRequest.size} 
                            currentPage={contactsRequest.page} 
                            pageSize={PAGE_SIZE}
                            changePage={proceedChangePage}
                            fatherLoaded={componentLoaded}
                        ></SearchTab>
                    :
                        ""
                }
                
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-12 sign"}>
                            <i>Powered by&nbsp;&nbsp;</i>
                            <img src={avanci_logo} alt={"Avanci"} />
                        </div>
                    </div>  
                </div>
            </div>
        </Fragment>
        );
}

export default SearchPage;