import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import IdentifiantContact from './IdentifiantContact';
import QualiteMoyenDeContact from './QualiteMoyenDeContact';
import QualificationComplementaire from './QualificationComplementaire';
import ComportementAchat from './ComportementAchat';
import OverlayLoader from '../Utils/Loader/OverlayLoader';
import DisconnectionButton from '../Utils/Buttons/DisconnectionButton';
import { GoToSearch, GoToErrorPage } from '../Utils/V6/NavigateFunction';

import AuthService from 'Modules/Utils/AuthService';
import {delete_session} from '../Utils/Session/Session';
import withRouter from '../Utils/V6/withRouter';

import avanci_logo from '../../Assets/IMG/Avanci_logo.png';

import '../../Assets/CSS/CheckBoxYesNo.css';

const ContactPage = props => {
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    const title                                                         = 'Starkle - Contact';
    const [isLoading, setIsLoading]                                     = useState(false);
    const [componentLoaded, setComponentLoaded]                         = useState({
                                                                            loaded:false,
                                                                            idContact:''
                                                                        });
    const [errorHandler, setErrorHandler]                               = useState({
        hasError : false,
        code : ""     
    });
    const [disconnected, setDisconnected]                               = useState(false);
    const [redirectToSearchPage, setRedirectToSearchPage]               = useState(false);

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    // --> component loading
    useEffect(() => {
        try{
            if(props.params.id !== undefined && props.params.id !== null)  setComponentLoaded({loaded:true, idContact:props.params.id});
            else setRedirectToSearchPage(true)
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000201"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deconnect = () => {
        try{
            if(componentLoaded.loaded){
                delete_session();
                AuthService.logout()
                setDisconnected(true);
            }
        }
        catch(error){
            setErrorHandler({hasError : true, code : "000202"});
        }
    }

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#   RENDER  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    /* Redirection vers errorPage */   
    if(!componentLoaded.loaded) return(<></>)
    return (
        <Fragment>
            <GoToErrorPage error={errorHandler}></GoToErrorPage>
            <GoToSearch redirect={redirectToSearchPage}></GoToSearch>
            <Helmet>
                <title>{title} #{componentLoaded.idContact}</title>
            </Helmet>

            <OverlayLoader hidden={!isLoading} fatherLoaded={componentLoaded.loaded} errorCode={"000204"}></OverlayLoader>

            <div className={"container-fluid page-log"}>
                <header className={"container"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <img src="img/" alt="" className={"img-fluid"} />
                        </div>
                        <div className={"col alignRight"}>
                            <nav id={"navTop"}>
                                <ul>
                                    <li>
                                        <DisconnectionButton deconnect={deconnect} disconnected={disconnected} 
                                            fatherLoaded={componentLoaded.loaded} errorCode={"000203"}></DisconnectionButton>
                                    </li>
                                    {/* <li>
                                        <button type={"submit"} className={"exporter"}>
                                            Exporter&nbsp;
                                            <img src={export_logo} alt={"exporter"} height={"20px"} width={"20px"} />
                                        </button>
                                    </li> */}
                                    <li>
                                        <button type={"submit"} className={"btn btn-primary"} onClick={() => {if(componentLoaded.loaded) setRedirectToSearchPage(true);}}>
                                            Rechercher un contact
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </header>
            </div>
            
            <div className={"container-fluid"}>
                <Fragment>
                    <IdentifiantContact 
                        idContact={componentLoaded.idContact}
                        deconnect={deconnect}
                        setErrorHandler={setErrorHandler}
                        setIsLoading={setIsLoading}
                        fatherLoaded={componentLoaded.loaded}
                    ></IdentifiantContact>
                    <QualiteMoyenDeContact 
                        idContact={componentLoaded.idContact}
                        deconnect={deconnect}
                        setErrorHandler={setErrorHandler}
                        fatherLoaded={componentLoaded.loaded}
                    ></QualiteMoyenDeContact>
                    <QualificationComplementaire 
                        idContact={componentLoaded.idContact}
                        deconnect={deconnect}
                        setErrorHandler={setErrorHandler}
                        fatherLoaded={componentLoaded.loaded}
                    ></QualificationComplementaire>
                    <ComportementAchat
                        idContact={componentLoaded.idContact}
                        deconnect={deconnect}
                        setErrorHandler={setErrorHandler}
                        fatherLoaded={componentLoaded.loaded}
                    ></ComportementAchat>
                </Fragment>
            </div>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12 sign"}>
                        <i>Powered by&nbsp;&nbsp;</i>
                        <img src={avanci_logo} alt={"Avanci"} />
                    </div>
                </div>  
            </div>
        </Fragment>
    );
}

export default withRouter(ContactPage);