import React, { useState, useEffect, Fragment }         from 'react';

import { GoToLogin }                                    from '../V6/NavigateFunction';

import disconnection_logo                               from '../../../Assets/IMG/Disconnection.svg';

// background: url('../img/frise.png') bottom right no-repeat;

const DisconnectionButton = props => {
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    const [componentLoaded, setComponentLoaded]         = useState(false);

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    // --> component loading
    useEffect(() => {
        try{
            setComponentLoaded(true);
            return () => {
                setComponentLoaded(false);
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : props.errorCode});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#   RENDER  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    if(!(props.fatherLoaded && componentLoaded)) return(<></>);
    return (
        <Fragment>
            <GoToLogin redirect={props.disconnected}></GoToLogin>
            <button type={"submit"} className={"deconnexion"} onClick={props.deconnect}>
                Se déconnecter&nbsp;
                <img src={disconnection_logo} alt={"déconnexion"} height={"20px"} width={"20px"} />
            </button>
        </Fragment>
    );
}
export default DisconnectionButton;