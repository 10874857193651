import axios from 'axios';
import tokenService from "Modules/Utils/TokenService";
import { get_session, create_session } from '../Session/Session';

export default class StarkleApiV1 {
  constructor() {
    this.connection = axios.create({
      baseURL: "/api",
      headers: {
        'Content-Type': 'application/json',
        'x-ava-ui-version': process.env.REACT_APP_VERSION,
      }
    })

    this.connection.interceptors.request.use(
      (config) => {
        const token = tokenService.getLocalAccessToken();
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        
        const session = get_session();
        config.headers["x-ava-session-id"] = session?.id;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.connection.interceptors.response.use(
      (response) => response,
      async (err) => {    
        const originalRequest  = err.config;
        // console.info("response interceptor", err)
        // console.info("originalRequest?.url", originalRequest?.ur, !(["/auth/login", "/auth/refreshtoken"].includes(originalRequest?.url)))
        // console.info("err.response?.status", err.response?.status, err.response?.status === 401)
        // console.info("originalRequest._retried", originalRequest._retried, !originalRequest._retried)

        if (
          !(["/auth/login", "/auth/refreshtoken"].includes(originalRequest?.url)) 
          && err.response?.status === 401
          && !originalRequest._retried
        ) {
          // console.log(originalRequest?.url)
          originalRequest._retried = true;
          try {
            await this.refreshToken();
            return this.connection({...originalRequest, headers: originalRequest.headers.toJSON()});
          } catch (refresh_error) {
            console.warn("Erreur refreshing token", refresh_error)
            return Promise.reject(err)
          }
        }
    
        return Promise.reject(err);
      }
    );
  }
  
  async login(username, password) {
    const response = await this.connection.post(
      `/auth/login`, 
      {
        username,
        password,
      }
    )
    if (response.data.access_token) {
      tokenService.setUser(response.data)
    }
    return response
  }

  async refreshToken() {
    const refresh_token = tokenService.getLocalRefreshToken()
    if (!refresh_token) {
      throw new Error("no refresh token found")
    }
    const response = await this.connection.post("/auth/refreshtoken", { refresh_token });
    if (response.status === 200) {
      tokenService.setUser(response.data);
      create_session(response.data?.user_data?.id);
    }
    return response;
  }

  search({
    nom, prenom, code_postal, email, carte_fid, id,
    page, page_size,
    old_amount,
  }) {
    return this.connection.post(
      `/search/contact`, 
      {
        nom,
        prenom,
        code_postal,
        email,
        carte_fid,
        id,

        page: parseInt(page),
        page_size,
        old_amount: parseInt(old_amount),
      }
    )
  }

  blocIdentifiant(idContact) {
    return this.connection.get(`/contact/${idContact}`)
  }

  comportementAchat(idContact) {
    return this.connection.get(`/contact/${idContact}/comportement-achat`)
  }

  qualiteMoyenContact(idContact) {
    return this.connection.get(`/contact/${idContact}/qualite-moyen-de-contact`)
  }

  qualificationComplementaire(idContact) {
    return this.connection.get(`/contact/${idContact}/qualification-complementaire`)
  }
}
