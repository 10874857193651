export function parseJwt(token) {
    let decodedToken = null;
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        decodedToken = JSON.parse(jsonPayload);
    } catch (error) {
        // console.warn("parseJwt", token, error);
    }

    let isExpired = true;
    try {
        const expirationDate = new Date(0);
        expirationDate.setUTCSeconds(decodedToken?.exp || 0);
        isExpired = expirationDate.valueOf() < new Date().valueOf();
    } catch (error) {
        // console.warn("parseJwt", token, error);
    }

    return {
        decodedToken,
        isExpired
    };
};


export function formatNumber(number, formatterOptions) {
    if (number === undefined || number === null) {
        return number;
    }
    const defaultOptions = {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }
    const formatter = new Intl.NumberFormat('fr', {...defaultOptions, ...formatterOptions});
    return formatter.format(number);
}


export function formatCurrency(number, formatterOptions) {
    return formatNumber(number, {
        style: 'currency', 
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...formatterOptions
    })
}