import tokenService from "Modules/Utils/TokenService";

/**
 * Affiche une barre de debug.
 * 
 * Bookmarklet pour (dés)activer l'affichage : 
 * javascript:(function(){var val=JSON.parse(localStorage.getItem("devbar")||'{"show": false}');var newVal={show:true,};try{newVal={...val,show:!val.show,};}catch(err){}localStorage.setItem("devbar",JSON.stringify(newVal));})()
 */

const DeveloperBar = () => {
    let devbar = null;
    try {
        devbar = JSON.parse(
            localStorage.getItem("devbar")
        );
    } catch (e){}
    if (devbar === null) {
        devbar = { show: false }
    }
    if (!devbar?.show) {
        return;
    }

    return (
        <div>
            <button onClick={() => tokenService.setUser({...tokenService.getUser(), access_token: "bad"})}>sabotter l'access token</button>
            <button onClick={() => tokenService.setUser({...tokenService.getUser(), refresh_token: "bad"})}>sabotter le refresh token</button>
            <button onClick={() => tokenService.removeUser()}>remove user</button>
        </div>
    );
}

export default DeveloperBar;