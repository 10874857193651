import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import ContentLoader from "react-content-loader"

import CheckBoxYesNo from '../Utils/Inputs/CheckBoxYesNo';
import CustomInput from '../Utils/Inputs/CustomInput';
import api from '../Utils/API';
import { update_session, get_session} from '../Utils/Session/Session';
import { formatCurrency } from '../Utils'

const ComportementAchat = props => {
    
    const navigate = useNavigate();
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    const [componentLoaded, setComponentLoaded]     = useState(false);
    const [wasOpened, setWasOpened]                 = useState(false);
    const [isOpened, setIsOpened]                   = useState(false);
    const [isLoading, setIsLoading]                 = useState(false);
    const [inputsValues, setInputsValues]           = useState({
        date_prem_cde: undefined,
        date_dern_cde: undefined,
        actif_periode: undefined,
        ca_ttc_prem_cde: undefined,
        ca_ttc_dern_cde: undefined,
        canal_prem_cde: undefined,
        canal_dern_cde: undefined,
        mmc: undefined
    });
    
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    
    // --> component loading
    useEffect(() => {
        try{
            setComponentLoaded(true);
            return () => {
                setComponentLoaded(false);
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : "000207"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        try{
            if(componentLoaded){
                if(wasOpened){
                    setIsLoading(true);
                    if(!update_session()) props.deconnect();
                    else{
                        let idSession = get_session();
                        api.comportementAchat(parseInt(props.idContact), idSession["id"])
                            .then((response) => {
                                if(componentLoaded){
                                    let status = response.request.status;
                                    if(status !== 200) {
                                        props.setErrorHandler({hasError : true, code : "000227"});
                                        return;
                                    }
                                    setInputsValues(response.data);
                                }
                            })
                            .catch((error) => {
                                if (error.response?.status === 401) {
                                    return navigate("/");
                                }
                                try{
                                    if(componentLoaded){
                                        // api key not authorized
                                        let status = error.response.request.status;
                                        if (status === 401) {
                                            if(error.response.data.errorMessage === undefined) props.setErrorHandler({hasError : true, code : "000224"});
                                            props.setErrorHandler({hasError : true, code : "000220"});
                                        }
                                        if (status === 500) {
                                            if(error.response.data.errorMessage === undefined) props.setErrorHandler({hasError : true, code : "000225"});
                                            props.setErrorHandler({hasError : true, code : "00022a"});
                                        } else {
                                            props.setErrorHandler({hasError : true, code : "00022b"});
                                        }
                                    }
                                }catch(error){
                                    props.setErrorHandler({hasError : true, code : "000229"});
                                }
                            })
                            .finally(() => {
                                setIsLoading(false)
                            });
                    }
                }
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : "000208"});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wasOpened]);

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#   RENDER  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    if(!(componentLoaded && props.fatherLoaded)) return(<></>);
    return (
        <div className={"container box"}>
            <div className={"row"}>
                <div className={"col padd0 onglet"}>
                    <CustomInput type={"checkbox"} className={"accordeon"} id={"accordeon3"} defaultChecked={isOpened} onChange={() => {setIsOpened(!isOpened); setWasOpened(true)}} />
                    <label htmlFor ={"accordeon3"} className={"accordeon-title"}>Comportement d'achat</label>
                </div>
            </div>
            {isOpened ?
                isLoading ?
                    <ContentLoader 
                        speed={2}
                        width={800}
                        height={300}
                        viewBox="0 0 400 160"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="100" y="0" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="0" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="0" rx="0" ry="0" width="52" height="15" /> 

                        <rect x="0" y="28" rx="0" ry="0" width="800" height="1" /> 
                        
                        <rect x="0" y="45" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="100" y="45" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="45" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="45" rx="0" ry="0" width="52" height="15" /> 
                        
                        <rect x="0" y="70" rx="0" ry="0" width="800" height="1" /> 

                        <rect x="0" y="80" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="100" y="80" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="80" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="80" rx="0" ry="0" width="52" height="15" /> 
                        
                        <rect x="0" y="110" rx="0" ry="0" width="800" height="1" /> 

                        <rect x="0" y="120" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="100" y="120" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="200" y="120" rx="0" ry="0" width="52" height="15" /> 
                        <rect x="300" y="120" rx="0" ry="0" width="52" height="15" /> 

                        <rect x="0" y="145" rx="0" ry="0" width="800" height="1" />
                    </ContentLoader>
                :
                    <Fragment>
                        <div className={"bloc-3"}>
                            <div className={"row borderBottom-achat"}>
                                <div className={"grid column-3"}>
                                    <div>
                                        <span className={"w-30"}>Premier achat</span>
                                        <span className={"w-20"}>Dernier achat</span>
                                    </div>
                                    <div>
                                        <span className={"w-30"}>12 derniers mois</span>
                                    </div>
                                    <div></div>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"grid column-3"}>
                                    <div >
                                        <label>Date</label>
                                        <CustomInput type={"text"} id={'date_prem_cde'} value={inputsValues.date_prem_cde || ""} readOnly />
                                        <CustomInput type={"text"} id={'date_dern_cde'} value={inputsValues.date_dern_cde || ""} readOnly />
                                    </div>
                                    {/* <div>
                                        <label>Nbre d'achats</label>
                                        <CustomInput type={"text"} id={'segmentation'} value={inputsValues.segmentation || ""} readOnly />
                                    </div> */}
                                    <CheckBoxYesNo id={'actif_periode'} checked={inputsValues.actif_periode} label={"Actif sur la période :"} centered={true} fatherLoaded={componentLoaded} />
                                </div>
                            </div>
                        
                            <div className={"row"}>
                                <div className={"grid column-3"}>
                                    <div>
                                        <label>CA TTC</label>
                                        <CustomInput type={"text"} id={'ca_ttc_prem_cde'} value={formatCurrency(inputsValues.ca_ttc_prem_cde) || ""} readOnly />
                                        <CustomInput type={"text"} id={'ca_ttc_dern_cde'} value={formatCurrency(inputsValues.ca_ttc_dern_cde) || ""} readOnly />
                                    </div>
                                    {/* <div>
                                        <label>CA TTC</label>
                                        <CustomInput type={"text"} id={'ca_ttc_dern_cde'} value={inputsValues.ca_ttc_dern_cde || ""} readOnly />
                                    </div> */}
                                    {/* <div>
                                        <label>Score affinités produits</label>
                                        <CustomInput type={"text"} id={'af'} value={inputsValues.ca_ttc_dern_cde || ""} readOnly />
                                    </div> */}
                                </div>
                            </div>
                        
                            <div className={"row"}>
                                <div className={"grid column-3"}>
                                    <div>
                                        <label>Canal</label>
                                        <CustomInput type={"text"} id={'canal_prem_cde'} value={inputsValues.canal_prem_cde || ""} readOnly />
                                        <CustomInput type={"text"} id={'canal_dern_cde'} value={inputsValues.canal_dern_cde || ""} readOnly />
                                    </div>
                                    <div>
                                        <label>Panier moyen TTC</label>
                                        <CustomInput type={"text"} id={'mmc'} value={formatCurrency(inputsValues.mmc) || ""} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
            :
                ""
            }
        </div>
    );
}

export default ComportementAchat;