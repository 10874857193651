import 'Assets/CSS/CustomInput.css';

const CustomInput = props => {
    let { className, value, ...otherProps } = props
    if (!value) { 
        className = `emptyinput ${className}`
    }
    return <input 
        className={className} 
        value={value} 
        {...otherProps}
    />
}
export default CustomInput