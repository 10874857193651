import { useNavigate }                                                  from 'react-router-dom';
import React, { useEffect }                                             from 'react';


const GoToSearch = props => {
    const navigate = useNavigate();

    useEffect(() => {
        try{
            if(props.redirect) navigate("/search");
        }
        catch(error){
            navigate(`/error/${"#090000"}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.redirect])
    
    return(<></>);
}

const GoToLogin = props => {
    const navigate = useNavigate();

    useEffect(() => {
        try{
            if(props.redirect) navigate("/");
        }
        catch(error){
            navigate(`/error/${"#090001"}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.redirect])
    
    return(<></>);
}

const GoToContact = props => {
    const navigate = useNavigate();

    useEffect(() => {
        try{
            if(props.redirect.redirect) navigate(`/contact/${props.redirect.id}`);
        }
        catch(error){
            navigate(`/error/${"#090002"}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.redirect])
    
    return(<></>);
}

const GoToErrorPage = props => {
    const navigate = useNavigate();

    useEffect(() => {
        try{
            if(props.error.hasError) navigate(`/error/${props.error.code}`);
        }
        catch(error){
            navigate(`/error/${"#090003"}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.error])
    
    return(<></>);
}

export {GoToSearch, GoToLogin, GoToContact, GoToErrorPage};