import React, { Fragment, useState, useEffect }                         from 'react';
import { Helmet }                                                       from 'react-helmet-async';

import { GoToLogin }                                                    from '../Utils/V6/NavigateFunction';

import avanciLogo                                                       from '../../Assets/IMG/Avanci_logo.png';
import avanciVisualition                                                from '../../Assets/IMG/login_visualisation_donnees.png';

function Error404NotFound() {
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    const title                                                         = 'Page inconnue';
    const [componentLoaded, setComponentLoaded]                         = useState(false);
    const [redirectToLogin, setRedirectToLogin]                         = useState(false);  

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    useEffect(() => {
        try{
            setComponentLoaded(true);
        }
        catch(error){
            console.log(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#   RENDER  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    /* Render normal HTML */
    return (
        <Fragment>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <GoToLogin redirect={redirectToLogin}></GoToLogin>

            <section className={"hero page-log"}>
                <div className={"container-home"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-4 error"}>
                            <div className={""}>
                                <h1 className={""}>Perdu(e) ?</h1>
                                <p>La page que vous cherchez n'existe pas ...</p>
                                <button className={"btn-yellow"} onClick={() => {if(componentLoaded) setRedirectToLogin(true);}}>Retour à l'accueil</button>
                            </div>         
                        </div>

                        <div className={"col-12 col-md-8 hero-img"}>
                            <img src={avanciVisualition} className={"img-fluid"} alt={"logo avanci"} />
                        </div>

                    </div>
                    <svg className={"hero-deco"} xmlns={"http://www.w3.org/2000/svg"} viewBox={"0 0 1367 750.5"}>
                        <defs>
                            <clipPath id={"adeco"}>
                                <path transform={"translate(553)"} fill={"#fff"} stroke={"#707070"} d={"M0 0h1367v750.5H0z"} />
                            </clipPath>
                            <clipPath id={"bdeco"}>
                                <path  fill={"none"} d={"M0 0h338.359v1124.787H0z"} />
                            </clipPath>
                        </defs>
                        <g transform={"translate(-553)"} clipPath={""}>
                            <g >
                                <g  transform={"translate(1581.64 107.439)"} clipPath={""}>
                                    <path d={"M282.12 903.065a56.238 56.238 0 1156.24-56.24 56.241 56.241 0 01-56.24 56.24"} fill={"#00f3ff"} />
                                    <path d={"M279.753 1015.544a56.238 56.238 0 1156.24-56.24 56.241 56.241 0 01-56.24 56.24"} fill={"#0019ff"} />
                                    <path d={"M280.349 334.047a56.238 56.238 0 1156.24-56.24 56.241 56.241 0 01-56.24 56.24"} fill={"#0019ff"} />
                                    <path d={"M280.349 449.25a56.238 56.238 0 1156.24-56.238 56.24 56.24 0 01-56.24 56.238"} fill={"#0019ff"} />
                                    <path d={"M281.197 562.393a56.239 56.239 0 000-112.478h-42.552v19.49a56.222 56.222 0 0042.553 92.988"} fill={"#00f3ff"} />
                                    <path d={"M168.717 898.698a56.238 56.238 0 1156.24-56.238 56.24 56.24 0 01-56.24 56.238"} fill={"#00f3ff"} />
                                    <path d={"M168.717 562.392a56.238 56.238 0 1156.24-56.24 56.241 56.241 0 01-56.24 56.24"} fill={"#0019ff"} />
                                    <path d={"M163.266 112.477a56.238 56.238 0 1156.24-56.24 56.241 56.241 0 01-56.24 56.24"} fill={"#0019ff"} />
                                    <path d={"M226.572 218.803h106.326V112.477a106.327 106.327 0 00-106.326 106.326"} fill={"#00f3ff"} />
                                    <path d={"M224.957 1124.793h112.479v-112.48a112.479 112.479 0 00-112.478 112.48"} fill={"#00f3ff"} />
                                    <path d={"M223.497 789.61a112.478 112.478 0 000-224.957h-85.1v38.982a112.443 112.443 0 0085.1 185.974"} fill={"#0019ff"} />
                                    <path d={"M114.931 341.291a112.478 112.478 0 010-224.957h85.108v38.982a112.443 112.443 0 01-85.105 185.974"} fill={"#00f3ff"} />
                                    <path d={"M112.477 1124.787a112.478 112.478 0 010-224.957h85.105v38.982a112.443 112.443 0 01-85.105 185.974"} fill={"#0019ff"} />
                                    <path d={"M166.458 449.915a56.239 56.239 0 010-112.478h42.552v19.49a56.222 56.222 0 01-42.552 92.988"} fill={"#00f3ff"} />
                                    <path d={"M279.734 0a56.239 56.239 0 000 112.478h42.553v-19.49A56.222 56.222 0 00279.734 0"} fill={"#0019ff"} />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className={"powered"}>
                    <i>
                        Powered by&nbsp;&nbsp;
                    </i>
                    <img src={avanciLogo} alt={"Avanci"} />
                </div>
            </section>
        </Fragment>
    );
}

export default Error404NotFound;