import React, {useState, useEffect, useRef}                                 from 'react';

const SearchBar = props => {
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    const [componentLoaded, setComponentLoaded]                             = useState(false);
    const refForm                                                           = useRef();
    let inputs = [
        [
            {"label":props.inputs.nomLabel, "id":props.inputs.nom, "default":props.cache.nom
                , "errorMessage":props.inputsError.nomMessage, "hasError":props.inputsError.nom},
            {"label":props.inputs.prenomLabel, "id":props.inputs.prenom, "default":props.cache.prenom
                , "errorMessage":props.inputsError.prenomMessage, "hasError":props.inputsError.prenom},
            {"label":props.inputs.codePostalLabel, "id":props.inputs.codePostal, "default":props.cache.codePostal
                , "errorMessage":props.inputsError.codePostalMessage, "hasError":props.inputsError.codePostal}
        ], 
        [
            {"label":props.inputs.emailLabel, "id":props.inputs.email, "default":props.cache.email
                , "errorMessage":props.inputsError.emailMessage, "hasError":props.inputsError.email},
            {"label":props.inputs.carteFidLabel, "id":props.inputs.carteFid, "default":props.cache.carteFid
                , "errorMessage":props.inputsError.carteFidMessage, "hasError":props.inputsError.carteFid},
            {"label":props.inputs.idLabel, "id":props.inputs.id, "default":props.cache.id
                , "errorMessage":props.inputsError.idMessage, "hasError":props.inputsError.id}
        ]
    ];
    
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    // --> component loading
    useEffect(() => {
        try{
            setComponentLoaded(true);
            return () => {
                setComponentLoaded(false);
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : "00010g"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onKeyPressEnter = (event) => {
        if (event.key === "Enter") props.searchClient();
    }
        
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#   RENDER  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    if(!(props.fatherLoaded && componentLoaded)) return(<></>);
    return (
        <form className={"container first"} id={"recherche"}  onSubmit={(e) => {e.preventDefault(); props.searchClient();}} ref={refForm} onKeyUp={onKeyPressEnter} tabIndex={0}>
            <div className={"row"}>
                <div className={"col padd0"}>
                    <h2>Rechercher un contact par :</h2>
                </div>
            </div>
            {
                inputs.map((row, i) => {
                    return(
                        <div className={"row"} key={i}>
                            {
                                row.map((input, j) => {
                                    return(
                                        <div className={"col champs"} key={j}>
                                            <label>{input.label}</label>
                                            <div>
                                                <input className={"form-control" + (input.hasError ? " is-invalid" : "")} id={input.id} defaultValue={input.default} />
                                            </div>
                                            {
                                                input.hasError ? 
                                                    <div id={input.id + "_error"} className={"invalid-feedback search-client-error"}>{input.errorMessage}</div>
                                                :
                                                    ""
                                            }
                                            
                                        </div>
                                    );
                                })
                            }
                        </div>
                    );
                })
            }
            
            <div className={"row justify-content-end"}>
                <div className={"col-3 btn-rch"}>
                    <button type={'submit'} className={"btn btn-primary"}>Rechercher</button>
                </div>
            </div>
        </form>
    );
}

export default SearchBar;