import React, { useState, useEffect }                                       from 'react';
import loader                                                               from '../../../Assets/IMG/loader.gif';

const OverlayLoader = props => {
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    const [componentLoaded, setComponentLoaded]                             = useState(false);
    
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    // --> component loading
    useEffect(() => {
        try{
            setComponentLoaded(true);
            return () => {
                setComponentLoaded(false);
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : props.errorCode});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(!(props.fatherLoaded && componentLoaded)) return(<></>);
    return(
        <div className={props.hidden ? "hidden" : ""} >
            <div className={"container-fluid overlay"}>
                <div className={"row"}>
                    <div className={"loader"}>
                        <img src={loader} alt={"loading"} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OverlayLoader;