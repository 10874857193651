import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import CheckBoxYesNo from '../Utils/Inputs/CheckBoxYesNo';
import CustomInput from '../Utils/Inputs/CustomInput';
import api from '../Utils/API';
import { update_session} from '../Utils/Session/Session';


const IdentifiantContact = props => {
    
    const navigate = useNavigate();
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    const [componentLoaded, setComponentLoaded]     = useState(false);
    const [inputsValues, setInputsValues] = useState({
        id_ava: "",
        code_contact: "",
        num_carte_fid: "",
        genre: "",
        prenom: "",
        nom: "",
        adresse1: "",
        adresse2: "",
        adresse3: "",
        adresse4: "",
        code_postal: "",
        ville: "",
        pays: "",
        email: "",
        tel_fixe: "",
        tel_tel_mobile: "",
        naissance_dt: "",
        type_contact: "",
        is_anonymise: ""
      });
    
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    // --> component loading
    useEffect(() => {
        try{
            setComponentLoaded(true);
            return () => {
                setComponentLoaded(false);
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : "000205"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        try{
            if(props.fatherLoaded && componentLoaded){
                props.setIsLoading(true);
                if(!update_session()) props.deconnect();
                else{
                    api.blocIdentifiant(parseInt(props.idContact))
                        .then((response) => {
                            if(componentLoaded){
                                let status = response.request.status;
                                if(status !== 200) {
                                    props.setErrorHandler({hasError : true, code : "000217"});
                                    return
                                }
                                setInputsValues(response.data);
                            }
                        })
                        .catch((error) => {
                            if (error.response?.status === 401) {
                                return navigate("/");
                            }
                            try{
                                if(componentLoaded){
                                    // api key not authorized
                                    let status = error.response.request.status;
                                    if (status === 401) {
                                        if(error.response.data.errorMessage === undefined) props.setErrorHandler({hasError : true, code : "000214"});
                                        props.setErrorHandler({hasError : true, code : "000210"});
                                    }
                                    if (status === 500) {
                                        if(error.response.data.errorMessage === undefined) props.setErrorHandler({hasError : true, code : "000215"});
                                        props.setErrorHandler({hasError : true, code : "00021a"});
                                    } else {
                                        props.setErrorHandler({hasError : true, code : "00021b"});
                                    }
                                }
                            }catch(error){
                                props.setErrorHandler({hasError : true, code : "000219"});
                            }
                        })
                        .finally(() => {
                            props.setIsLoading(false)
                        });
                }
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : "000206"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentLoaded]);

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#   RENDER  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    if(!(componentLoaded && props.fatherLoaded)) return(<></>);
    return (
        <div className={"container first"}>
            <div className={"row"}>
                <div className={"col padd0"}>
                    <h2>Identifiant client</h2>
                </div>
            </div>
            {/* BLOC IDENTITE CLIENT LINE 1 */}
            <div className={"row"}>
                <div className={"grid column-6"}>
                    <div>
                        <label htmlFor={'code_contact'}>ID Client Unique</label>
                        <CustomInput type={"text"} className={"w-65"} id={'code_contact'} value={inputsValues.code_contact || ""} readOnly />
                    </div>
                    <div>
                        <label htmlFor={'num_carte_fid'}>N° fidélité</label>
                        <CustomInput type={"text"} className={""} id={'num_carte_fid'} value={inputsValues.num_carte_fid || ""} readOnly />
                    </div>
                    <div>
                        <label htmlFor={'genre-homme'}>Homme</label>
                        <input type={"radio"} id={'genre-homme'} name={'genre'} className={"custom-control-input"} 
                           readOnly checked={inputsValues.genre === "H"} />
                    </div>
                    <div>
                        <label htmlFor={'genre-femme'}>Femme</label>
                        <input type={"radio"} id={'genre-femme'} name={'genre'} className={"custom-control-input"} 
                            readOnly checked={inputsValues.genre === "F"} />
                    </div>
                    <div>
                        <label htmlFor={'prenom'}>Prénom</label>
                        <CustomInput type={"text"} className={"w-80"} id={'prenom'} value={inputsValues.prenom || ""} readOnly />
                    </div>
                    <div>
                        <label htmlFor={'nom'}>Nom</label>
                        <CustomInput type={"text"} className={"w-85"} id={'nom'} value={inputsValues.nom || ""} readOnly />
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"grid column-3"}>
                    {/* LINE 2 */}
                    <div>
                        <label htmlFor={'adresse1'}>Adresse 1</label>
                        <CustomInput type={"text"} className={"w-80"} id={'adresse1'} value={inputsValues.adresse1 || ""} readOnly/>
                    </div>
                    <div>
                        <label htmlFor={'adresse2'}>Adresse 2</label>
                        <CustomInput type={"text"} className={"w-80"} id={'adresse2'} value={inputsValues.adresse2 || ""} readOnly/>
                    </div>
                    <div>
                        <label htmlFor={'adresse3'}>Adresse 3</label>
                        <CustomInput type={"text"} className={"w-80"} id={'adresse3'} value={inputsValues.adresse3 || ""} readOnly/>
                    </div>
                    <div>
                        <label htmlFor={'adresse4'}>Adresse 4</label>
                        <CustomInput type={"text"} className={"w-80"} id={'adresse4'} value={inputsValues.adresse4 || ""} readOnly/>
                    </div>
                    {/* LINE 3 */}
                    <div>
                        <label htmlFor={'code_postal'}>Code postal</label>
                        <CustomInput type={"text"} className={"w-40"} id={'code_postal'} value={inputsValues.code_postal || ""} readOnly/>
                    </div>
                    <div>
                        <label htmlFor={'ville'}>Ville</label>
                        <CustomInput type={"text"} className={"w-80"} id={'ville'} value={inputsValues.ville || ""} readOnly/>
                    </div>
                    <div>
                        <label htmlFor={'pays'}>Pays</label>
                        <CustomInput type={"text"} className={"w-80"} id={'pays'} value={inputsValues.pays || ""} readOnly />
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"grid column-14-34"}>
                    {/* LINE 4 */}
                    <div>
                        <label htmlFor={'email'} className={"w-17"}>Email</label>
                        <CustomInput type={"text"} className={"w-80"} id={'email'} value={inputsValues.email || ""} readOnly />
                    </div>
                <div className={"grid ID-line4"}>
                    <div>
                        <label htmlFor={'tel_fixe'} className={"w-25"}>Téléphone</label>
                        <CustomInput type={"text"} className={""} id={'tel_fixe'} value={inputsValues.tel_fixe || ""} readOnly />
                    </div>
                    <div>
                        <label htmlFor={'tel_mobile'}>Mobile</label>
                        <CustomInput type={"text"} className={""} id={'tel_mobile'} value={inputsValues.tel_mobile || ""} readOnly />
                    </div>
                    <div>
                        <label htmlFor={'naissance_dt'}>Date de naissance</label>
                        <CustomInput type={"text"} id={'naissance_dt'} value={inputsValues.naissance_dt || ""} readOnly />
                    </div>
                </div>
                </div>
            </div>
            {/* LINE 5 */}
            <div className={"row"}>
                <div className={"grid ID-line5"}>
                    
                    <div>
                        <label htmlFor={'type_contact'} className={"w-27"}>Type Contact</label>
                        <CustomInput type={"text"} id={'type_contact'} value={inputsValues.type_contact || ""} readOnly />
                    </div>
                    <div>
                        <CheckBoxYesNo 
                            id={'is_anonymise'} 
                            checked={inputsValues.is_anonymise} 
                            label={"Anonymisé RGPD"} 
                            centered={true} 
                            fatherLoaded={componentLoaded} 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default IdentifiantContact;