let SESSION_EXPIRATION = 15 *60 *1000 // 15 minutes
const storage = localStorage;

export function create_session(id){
    storage.setItem("session_id", id);
    storage.setItem("session_expiration", Date.now().toString());
}

export function update_session(){
    if(exists_session()){
        storage.setItem("session_expiration", Date.now().toString()); 
        return true;
    }
    return false;
}

export function get_session(){
    return {
        id:parseInt(storage.getItem("session_id"))
    };
}


export function delete_session(){
    storage.removeItem("session_id"); 
    storage.removeItem("session_expiration");
}

function exists_session(){
    if(storage.getItem("session_id") === null || storage.getItem("session_expiration") === null) return false;
    else{
        if(new Date(parseInt(storage.getItem("session_expiration")) + SESSION_EXPIRATION) < Date.now()){
            delete_session();
            return false;
        }
        return true;
    }
}
