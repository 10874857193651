export function EmailValidator(email) {
    return String(email).toLowerCase().match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

function containsSpecificCharacter(str){
    for(let i = 0; i < str.length; i++){
        if (str[i].match(/^[@*/\-+?,!=§.]$/)) return true
    }
    return false;
}

function containsDigit(str){
    for(let i = 0; i < str.length; i++){
        if (str[i].match(/^[0-9]$/)) return true
    }
    return false;
}

function containsLowerCharacter(str){
    for(let i = 0; i < str.length; i++){
        if (str[i].match(/^[a-z]$/)) return true
    }
    return false;
}

function containsUpperCharacter(str){
    for(let i = 0; i < str.length; i++){
        if (str[i].match(/^[A-Z]$/)) return true
    }
    return false;
}

export function PasswordValidator(password) {
    let test_password = String(password);
    let cond1 = test_password.length >= 12;
    let cond2 = containsDigit(password);
    let cond3 = containsSpecificCharacter(password);
    let cond4 = containsLowerCharacter(password);
    let cond5 = containsUpperCharacter(password);

    let error_message = "";
    if(!cond1)          error_message = "Le mot de passe doit contenir au moins 12 caractères";
    else if(!cond2)     error_message = "Le mot de passe doit contenir au moins un chiffre";
    else if(!cond3)     error_message = "Le mot de passe doit contenir au moins un caractère spécial"
    else if(!cond4)     error_message = "Le mot de passe doit contenir au moins une lettre minuscule"
    else if(!cond5)     error_message = "Le mot de passe doit contenir au moins une lettre majuscule"

    return [cond1 && cond2 && cond3 && cond4 && cond5, error_message];
}

export function CPValidator(str){
    return str.match(/^[0-9]{1,5}$/);
}