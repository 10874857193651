import React, { useState, useEffect }               from 'react';

const CheckBoxYesNo = props => {
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    const [componentLoaded, setComponentLoaded]     = useState(false);
    
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    // --> component loading
    useEffect(() => {
        try{
            setComponentLoaded(true);
            return () => {
                setComponentLoaded(false);
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : "000250"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#   RENDER  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    
    if(!(props.fatherLoaded && componentLoaded)) return(<></>)
    return(
        <div className={"can-toggle switch"}>
            <input id={props.id} type={"checkbox"} checked={!!props.checked} readOnly />
            <label htmlFor={props.id}>
                {
                    props.label !== undefined && props.label !== null ?
                        <div className={"can-toggle__label-text"}>{props.label}</div>
                    : 
                        ""
                }
                <div className={"can-toggle__switch " + (props.centered ? "margin-2pcent" : "marginAuto")} data-checked={"OUI"} data-unchecked={"NON"}></div>
            </label>
        </div>
    );
}

export default CheckBoxYesNo;