import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async'; 
import DeveloperBar from './Utils/DeveloperBar';
import LoginPage from './Login/LoginPage';
import SearchPage from './Search/SearchPage';
import ContactPage from './Contact/ContactPage'; 
import Error404NotFound from './Error/Error404NotFound';
import ErrorPage from './Error/ErrorPage';

import '../Assets/CSS/bootstrap5.2.3.min.css';
import '../Assets/CSS/App.css';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* ================= Pages' URL ================= */}
      <Route exact path="/" element={<LoginPage />} />
      <Route exact path="search" element={<SearchPage />} />
      <Route exact path="contact/:id" element={<ContactPage />} />

      {/* ================= Errors' Handlers ================= */}
      {/* Redirecting page when no correct URL targeted */}
      <Route exact path="error/:code" element={<ErrorPage />} />
      {/* Redirecting page when no correct URL targeted */}
      <Route path="/*" element={<Error404NotFound />}/>
    </>
  )
)

const App = () => {
  return (
    <HelmetProvider>
      <DeveloperBar />
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}
export default App;