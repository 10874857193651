import React, { useEffect, useState, useRef, useContext } from 'react';
import AuthContext from "context/AuthProvider";
import { Helmet } from 'react-helmet-async';

import { GoToSearch } from '../Utils/V6/NavigateFunction';
import { EmailValidator } from '../Utils/Inputs/InputValidator';
import AuthService from '../Utils/AuthService';
import BootstrapInput from '../Utils/Inputs/BootstrapInput';
import { create_session } from '../Utils/Session/Session';

import avanciLogo from '../../Assets/IMG/Avanci_logo.png';
import avanciVisualition from '../../Assets/IMG/login_visualisation_donnees.png';

function LoginPage() {
    const title = 'Starkle - Connexion';
    const { setAuth } = useContext(AuthContext);

    const refEmail = useRef();

    const [redirectToSearch, setRedirectToSearch] = useState(false);    

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [outputErrorMessage, setOutputErrorMessage] = useState('');

    // Quand le composant est monté
    useEffect(() => {
        refEmail.current.focus();

        if (sessionStorage.getItem("cached_contact_search_inputs") !== null) {
            sessionStorage.removeItem("cached_contact_search_inputs");
        }
    }, []);

    // Lors d'une sasie utilsateur
    useEffect(() => {
        setOutputErrorMessage('');
    }, [email, password]);

    // --> checking email field
    const rulePasswordMustBeSet = (password) => {
        if (password === null || password === undefined || password === '') {
            return "Veuillez spécifier un mot de passe";
        }
    }

    const ruleEmailMustBeSet = (email) => {
        if (email === null || email === undefined || email === '') {
            return "Veuillez spécifier un email";
        } 
    }
    const ruleEmailMustBeValid = (email) => {
        if (!EmailValidator(email)) {
            return "Le format n'est pas celui d'un email";
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setEmailError(ruleEmailMustBeSet(email) || ruleEmailMustBeValid(email));
        setPasswordError(rulePasswordMustBeSet(password));
        if (emailError || passwordError) {
            return;
        }

        try {
            const response = await AuthService.login(email, password);
            if (response.request?.status === 401) {
                setOutputErrorMessage("Identifiants incorrects");
            } else if (response.request?.status === 200) {
                setAuth(response.data);
                create_session(response.data.user_data.id);
                setRedirectToSearch(true);
                setEmail('');
                setPassword('');
            }
            throw new Error("Server error");
        } catch (err) {
            if (!err?.response) {
                setOutputErrorMessage("Problème de connexion au serveur");
            } else if (err.response?.status === 401) {
                setOutputErrorMessage("Identifiants incorrects");
            } else {
                setOutputErrorMessage("Echec de l'authentification");
            }
        }
    };


    return (
        <section className={"hero page-log"}>
            <GoToSearch redirect={redirectToSearch}></GoToSearch>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <div className={"container-home"}>
                <div className={"row pad-log"}>
                    <div className={"col-12 col-md-4"}>
                        <div className={"md-3 center"}>
                            <h1 className={"log-title"}>Bienvenue sur l’espace de visualisation<br />de vos données client</h1>
                        </div>
                        <form 
                            id={"login"} 
                            onSubmit={handleSubmit}
                            tabIndex={0}
                        >
                            <div className={"center"}>
                                <img src="img/logo_client_small.png" alt="" className={"img-fluid"} />
                            </div>
                            <h2>Connexion</h2>
                            <div className={"invalid-feedback"}>{outputErrorMessage}</div>

                            <div className={"md-3"}>
                                <BootstrapInput 
                                    innerRef={refEmail}
                                    id={'login_email'} 
                                    title={"Identifiant"} 
                                    placeholder={"votre email"} 
                                    type={"email"} 
                                    className={"form-control"} 
                                    label_class={"form-label"}
                                    error_class={"invalid-feedback"} 
                                    error_message={emailError}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className={"mb-3"}>
                                <BootstrapInput 
                                    id={'login_password'} 
                                    title={"Mot de passe"}
                                    placeholder={"****"}
                                    type={"password"}
                                    className={"form-control"} 
                                    label_class={"form-label"}
                                    error_class={"invalid-feedback"}
                                    error_message={passwordError}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                />
                            </div>

                            <button type={'submit'} className={"btn btn-primary"}>Se connecter</button>
                            
                            <div className={"mb-3 center"}>
                                <a href={"mailto: helpdesk@avanci.fr"} className={"pass"}>Mot de passe oublié</a>
                            </div>
                        </form>
                    </div>

                    <div className={"col-12 col-md-8 hero-img"}>
                        <img src={avanciVisualition} className={"img-fluid"} alt={"visualisation avanci"} />
                    </div>

                </div>
                <svg className={"hero-deco"} xmlns={"http://www.w3.org/2000/svg"} viewBox={"0 0 1367 750.5"}>
                    <defs>
                        <clipPath id={"adeco"}>
                            <path transform={"translate(553)"} fill={"#fff"} stroke={"#707070"} d={"M0 0h1367v750.5H0z"} />
                        </clipPath>
                        <clipPath id={"bdeco"}>
                            <path fill={"none"} d={"M0 0h338.359v1124.787H0z"} />
                        </clipPath>
                    </defs>
                    <g transform={"translate(-553)"} clipPath={""}>
                        <g >
                            <g transform={"translate(1581.64 107.439)"} clipPath={""}>
                                <path d={"M282.12 903.065a56.238 56.238 0 1156.24-56.24 56.241 56.241 0 01-56.24 56.24"} fill={"#00f3ff"} />
                                <path d={"M279.753 1015.544a56.238 56.238 0 1156.24-56.24 56.241 56.241 0 01-56.24 56.24"} fill={"#0019ff"} />
                                <path d={"M280.349 334.047a56.238 56.238 0 1156.24-56.24 56.241 56.241 0 01-56.24 56.24"} fill={"#0019ff"} />
                                <path d={"M280.349 449.25a56.238 56.238 0 1156.24-56.238 56.24 56.24 0 01-56.24 56.238"} fill={"#0019ff"} />
                                <path d={"M281.197 562.393a56.239 56.239 0 000-112.478h-42.552v19.49a56.222 56.222 0 0042.553 92.988"} fill={"#00f3ff"} />
                                <path d={"M168.717 898.698a56.238 56.238 0 1156.24-56.238 56.24 56.24 0 01-56.24 56.238"} fill={"#00f3ff"} />
                                <path d={"M168.717 562.392a56.238 56.238 0 1156.24-56.24 56.241 56.241 0 01-56.24 56.24"} fill={"#0019ff"} />
                                <path d={"M163.266 112.477a56.238 56.238 0 1156.24-56.24 56.241 56.241 0 01-56.24 56.24"} fill={"#0019ff"} />
                                <path d={"M226.572 218.803h106.326V112.477a106.327 106.327 0 00-106.326 106.326"} fill={"#00f3ff"} />
                                <path d={"M224.957 1124.793h112.479v-112.48a112.479 112.479 0 00-112.478 112.48"} fill={"#00f3ff"} />
                                <path d={"M223.497 789.61a112.478 112.478 0 000-224.957h-85.1v38.982a112.443 112.443 0 0085.1 185.974"} fill={"#0019ff"} />
                                <path d={"M114.931 341.291a112.478 112.478 0 010-224.957h85.108v38.982a112.443 112.443 0 01-85.105 185.974"} fill={"#00f3ff"} />
                                <path d={"M112.477 1124.787a112.478 112.478 0 010-224.957h85.105v38.982a112.443 112.443 0 01-85.105 185.974"} fill={"#0019ff"} />
                                <path d={"M166.458 449.915a56.239 56.239 0 010-112.478h42.552v19.49a56.222 56.222 0 01-42.552 92.988"} fill={"#00f3ff"} />
                                <path d={"M279.734 0a56.239 56.239 0 000 112.478h42.553v-19.49A56.222 56.222 0 00279.734 0"} fill={"#0019ff"} />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>

            <div className={"powered"}>
                <i>Powered by&nbsp;&nbsp;</i>
                <img src={avanciLogo} alt={"Avanci"} />
            </div>
        </section>
    );
}

export default LoginPage;