
const BootstrapInput = ({ innerRef, id, type, title, label_class, placeholder, className, error_message, error_class, ...props }) => {
    return (
        <>
            <label 
                htmlFor={id} 
                className={label_class}
            >
                {title}
            </label>
            
            <input 
                ref={innerRef}
                id={id} 
                type={type} 
                placeholder={placeholder} 
                className={className + (error_message ? " is-invalid" : "")} 
                {...props}
            />
            
            <div 
                className={error_class} 
                style={{
                    visibility: error_message ? 'hidden' : 'visible'
                }}
            >
                {error_message}
            </div>
        </>
    );
}
export default BootstrapInput;