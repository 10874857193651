import React, { Fragment, useState, useMemo, useEffect }                                 from 'react';

import { GoToContact }                                                          from '../Utils/V6/NavigateFunction';
import ReactPaginate from 'react-paginate';


const SearchTab = props => {
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#  VARIABLES AND STATE  #---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    const [componentLoaded, setComponentLoaded]                             = useState(false);
    const [redirectToContact, setRedirectToContact]                         = useState({
                                                                                redirect : false,
                                                                                id : 0     
                                                                            });
    
    const pageCount = useMemo(() => Math.ceil(props.currentAmount/props.pageSize), [props.currentAmount, props.pageSize]);

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#  METHODS  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#

    // --> component loading
    useEffect(() => {
        try{
            setComponentLoaded(true);
            return () => {
                setComponentLoaded(false);
            }
        }
        catch(error){
            props.setErrorHandler({hasError : true, code : "00010f"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#   RENDER  #---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    // #---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#---#
    if(!(props.fatherLoaded && componentLoaded)) return(<></>);
    return (
        <Fragment>
            <GoToContact redirect={redirectToContact}></GoToContact>
            <div className={"container box"}>
                <div className={"row"}>
                    <div className={"col padd0"}>
                        <h2>
                            Résultats : <span>{props.currentAmount}</span>
                        </h2>
                    </div>
                </div>
                <div className={"row"}>
                    <table className={"table table-striped"} id={"listing-client"}>
                        <thead>
                            <tr>
                                <th scope={"row"} className={"left"}>#</th>
                                <th scope={"col"}>Nom</th>
                                <th scope={"col"}>Prénom</th>
                                <th scope={"col"}>Code postal</th>
                                <th scope={"col"}>Email</th>
                                <th scope={"col"}>N° de Fidélité</th>
                                <th scope={"col"}>ID client</th>
                                <th scope={"col"}>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.contacts.map((contact, i) => {
                                    return(
                                        <tr key={i}>
                                            <th scope={"row"}>{props.currentPage * props.pageSize + i + 1}</th>
                                            <td className={"order-1"}>{contact.nom}</td>
                                            <td className={"order-2"}>{contact.prenom}</td>
                                            <td className={"order-3"}>{contact.codepostal}</td>
                                            <td className={"order-4"}>{contact.mail}</td>
                                            <td className={"order-5"}>{contact.codecartefidelite}</td>
                                            <td className={"order-6"}>{contact.codeclient !== null ? contact.codeclient : <span className={"prospect"}>Prospect</span>}</td>
                                            <td className={"order-7"}><button type={"submit"} onClick={() => {if(componentLoaded) setRedirectToContact({redirect:true, id:contact.id_ava})}}>voir la fiche</button></td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div>
                    <nav aria-label={"navigation"} id={"pagination"}>
                        <ReactPaginate
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            forcePage={props.currentPage || -1}
                            onPageChange={({selected}) => props.changePage(selected)}
                            breakLabel="…"
                            previousLabel="«"
                            nextLabel="»"
                            renderOnZeroPageCount={null}
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item"
                            nextClassName="page-item"
                            previousLinkClassName="page-link"
                            pageLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            activeClassName="selected-page"
                        />
                    </nav>
                </div>
            </div>
        </Fragment>
    );
}

export default SearchTab;