import React            from 'react';
import { useParams }    from 'react-router-dom';

// since v6 props params are not passed in <routes element={}>
const withRouter = WrappedComponent => props => {
    const params = useParams();
  
    return (
      <WrappedComponent
        {...props}
        params={params}
      />
    );
  };

export default withRouter;