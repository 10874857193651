import api from "./API";
import tokenService from "Modules/Utils/TokenService";

class AuthService {
  async login(username, password) {
    const response = await api.login(username, password)

    if (response.data.accessToken) {
      tokenService.setUser(response.data);
    }

    return response;
  }

  logout() {
    tokenService.removeUser();
  }

  getCurrentUser() {
    return tokenService.getUser();
  }
}

export default new AuthService();
